.header-dropdown-card-wrapper {
  min-height: 100px;
  max-height: 325px;
  min-width: 580px;
  background: white;
  position: absolute;
  top: 10;
  left: -150px;
  box-shadow: 6px 36px 56px 5px rgba(0, 0, 0, 0.19);
  -webkit-box-shadow: 6px 36px 56px 5px rgba(0, 0, 0, 0.19);
  -moz-box-shadow: 6px 36px 56px 5px rgba(0, 0, 0, 0.19);
  gap: 30px;
  overflow-y: hidden;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  justify-content: center;
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 500ms, transform 500ms;
  animation: fade-in-slide-up 0.5s ease forwards;
}

.header-dropdown-card-wrapper-arrow-up {
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid white;
  position: absolute;
  top: 55px;
  left: 30%;
  z-index: 9999;
  box-shadow: 6px 36px 56px 5px rgba(0, 0, 0, 0.878);
  -webkit-box-shadow: 6px 36px 56px 5px rgba(0, 0, 0, 0.19);
  -moz-box-shadow: 6px 36px 56px 5px rgba(0, 0, 0, 0.19);
}

@keyframes fade-in-slide-up {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.header-dropdown-card-item-wrapper {
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  transition: all;
  transition-duration: 100ms;
  padding: 10px;

  & img {
    height: 50px;
    width: 50px;
  }
  &:hover {
    background: #f5f5f5;
    transform: scale(1.01);
  }
}

.solutions-link-card-item {
  transition: all;
  transition-duration: 100ms;
  color: $theme-color !important;
  width: 100%;
  &:hover {
    transform: scale(1.009);
    color: $theme-color !important;
    text-decoration: underline;
  }
}

.solutions-link-card-item a.active {
  color: $theme-color !important;
}

.border-b-1 {
  border-bottom: 1px solid $border-color;
}
