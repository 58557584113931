li .mobile-accordion-menu-wrapper {
  // background-color: red;
  width: 100%;
  height: 0;

  &.submenu {
    display: none;
    visibility: hidden;
    // padding-left: 20px !important;
  }
}

li .has-submenu::before {
  content: '\25B6'; /* Unicode character for a right-pointing triangle (▶) */
  padding-right: 5px;
  cursor: pointer;
  font-weight: 500 !important;
  margin-left: 20px;
}

li .has-submenu .active::before {
  transform: rotate(90deg);
}
