.noctil-support {
  background-color: #f3ebff09;
  h2 {
    margin-bottom: 80px;
    font-weight: 400;
  }
  .support-block {
    min-height: 224px;
    margin-bottom: 28px;
    position: relative;
    @media #{$sm-layout} {
      min-height: auto;
      height: auto;
      height: auto;
      margin-bottom: 40px;
    }
    @media #{$md-layout} {
      min-height: auto;
      height: auto;
      height: auto;
      margin-bottom: 40px;
    }
    p {
      margin-top: 20px;
    }
    .support-block-card-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 16px;
      // border: 1px solid $border-color;
      box-shadow: 1px 1px 7px 2px rgba(0, 0, 0, 0.103);
      -webkit-box-shadow: 1px 1px 7px 2px rgba(0, 0, 0, 0.192);
      -moz-box-shadow: 1px 1px 7px 2px rgba(0, 0, 0, 0.155);
      padding: 28px;
      padding-left: 16px;
      padding-top: 32px;
      line-break: normal;
      border-radius: 4px;
      background-color: white;
      & .title-wrapper {
        background: linear-gradient(98.1deg, #400d94 -1.58%, #76079d 100%);
        border: 1px solid white;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        padding-bottom: 0px;
      }
      & h4 {
        font-size: 26px;
        color: white;
        // line-height: 1px;
      }
      & img {
        width: 120px;
        height: 120px;
        object-fit: cover;
      }

      ul {
        list-style: circle;
        text-align: start;
        font-weight: 500;
        font-size: 18px;
      }
    }
    .support-content {
      position: absolute;
      bottom: 5px;
      width: 80%;
      margin-bottom: 30px;
      height: auto;
      @media #{$sm-layout} {
        position: relative;
        height: auto;
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
        margin-bottom: 40px;
      }
      @media #{$md-layout} {
        position: relative;
        height: auto;
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
        margin-bottom: 40px;
      }
      img {
        width: 100%;
      }
    }
  }
}
