.loader-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background: rgba(255, 255, 255, 0.9);
  z-index: 1;
}
.bg-gradient {
  background: linear-gradient(98.1deg, #400d94 -1.58%, #76079d 100%);
}
.mobile {
  display: none !important;
}
.desktop {
  display: flex;
}
@media (max-width: 992px) {
  .mobile {
    display: flex !important;
  }
  .desktop {
    display: none !important;
  }
}
@media (max-width: 992px) {
  .container,
  .container-md,
  .container-sm {
    max-width: 98%;
  }
}

@media only screen and (max-width: 575px) {
  .container {
    width: 100%;
  }
}
.navIcon {
  margin-right: 10px;
  top: -4px;
  position: relative;
}
.mobile .mainmenu .mobileLogo img {
  max-width: 150px;
}
.mobile .mainmenu .mobileLogo {
  text-align: right;
  margin-top: 54px;
  margin-bottom: 54px;
}
.mobile ul li {
  padding: 0px !important;
}
.mobile ul li a {
  padding: 20px !important;
  font-weight: 400 !important;
}
.desktop ul li a.active {
  font-weight: 700;
  color: #400d94 !important;
}
.mobile ul li.home a:before {
  content: url(../images/icons/nav-home-icon.svg);
  display: inline-block;
  margin-right: 10px;
  position: relative;
}
.mobile ul li.home a.active:before {
  content: url(../images/icons/nav-home-icon-hover.svg);
  display: inline-block;
  margin-right: 10px;
  position: relative;
}
.mobile ul li.about a:before {
  content: url(../images/icons/nav-about-icon.svg);
  display: inline-block;
  margin-right: 10px;
  position: relative;
  top: 4px;
}
.mobile ul li.about a.active:before {
  content: url(../images/icons/nav-about-icon-hover.svg);
  display: inline-block;
  margin-right: 10px;
  position: relative;
  top: 4px;
}
.mobile ul li.solutions a:before {
  content: url(../images/icons/nav-solutions-icon.svg);
  display: inline-block;
  margin-right: 10px;
  position: relative;
  top: 4px;
}
.mobile ul li.solutions a.active:before {
  content: url(../images/icons/nav-solutions-icon-hover.svg);
  display: inline-block;
  margin-right: 10px;
  position: relative;
  top: 4px;
}
.mobile ul li.resources a:before {
  content: url(../images/icons/nav-resources-icon.svg);
  display: inline-block;
  margin-right: 10px;
  position: relative;
  top: 4px;
}
.mobile ul li.resources a.active:before {
  content: url(../images/icons/nav-resources-icon-hover.svg);
  display: inline-block;
  margin-right: 10px;
  position: relative;
  top: 4px;
}
.mobile ul li a.active {
  border-radius: 6px;
  background: #4e12b1;
  color: #ffffff !important;
}
.mobile .rn-btn {
  display: block !important;
  margin-top: 30px;
  padding: 6px 40px !important;
}
.custom-modal .modal-header {
  border-bottom: none !important;
}
.custom-modal .title {
  font-style: normal;
  font-weight: 300;
  font-size: 40px;
  line-height: 50px;
  color: #1b1b1b;
  margin-bottom: 14px;
  display: inline-block;
}
.custom-modal p {
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 25px;
  color: #494949;
}
.custom-modal .modal-content {
  padding-left: 70px;
  padding-right: 70px;
  padding-top: 0px;
  padding-bottom: 40px;
}
@media (max-width: 992px) {
  .custom-modal .modal-content {
    padding-bottom: 40px;
  }
}
.modal-header .close {
  margin-right: -4.5rem;
}
.close {
  font-size: 3rem;
  font-weight: 300;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 1;
  margin-right: -66px;
}
.close:hover {
  opacity: 0.5;
}
.custom-modal form {
  margin-top: 40px;
}
.my-modal {
  width: 90vw;
  max-width: 640px;
}
.page-container {
  min-height: 50vh;
}
.desktop-show {
  display: block;
}
.mobile-show {
  display: none;
}
@media (max-width: 992px) {
  .desktop-show {
    display: none;
  }
  .mobile-show {
    display: block;
  }
}
