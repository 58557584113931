.subscribeNoctil {
  padding-top: 60px;
  padding-bottom: 60px;
  h2 {
    color: #ffffff;
    font-size: 60px;
    line-height: 65px;
    // font-weight: 500;
    @media #{$sm-layout} {
      font-size: 45px;
      line-height: 60px;
    }
    text-transform: capitalize;
  }
  h3 {
    color: #ffffff;
    // font-weight: 600;
    line-height: 32px;
  }
  h4 {
    color: #ffffff;
  }
  p {
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 35px;
    color: #ffffff;
  }
  img {
    width: 100%;
    padding-left: 30px;
    @media #{$sm-layout} {
      margin-top: 40px;
    }
    @media #{$md-layout} {
      margin-top: 40px;
    }
  }
}
