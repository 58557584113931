// Contact Styles

.contact-form--1 {
  label {
    display: block;
    margin-bottom: 0;
  }

  input,
  textarea {
    display: block;
    width: 100%;
    padding: 0 20px;
    border: 2px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    transition: all 0.3s ease;
    height: 50px;
    line-height: 46px;
    margin-bottom: 20px;
    outline: none;
    color: #1f1f25;
    font-size: 15px;
    letter-spacing: 0.1px;
    &:focus {
      border-color: $theme-color;
    }
    background: #fff;
  }
  textarea {
    height: 120px;
  }
}

/*==========================
    Contact Address 
============================*/

.rn-address {
  margin-top: 30px;
  border: 1px solid #ebebeb;
  padding: 40px;
  border-radius: 10px;
  padding-top: 54px;
  background: #fff;
  padding-bottom: 50px;
  .icon {
    border: 1px solid #ebebeb;
    width: 78px;
    height: 78px;
    color: $theme-color;
    line-height: 73px;
    text-align: center;
    border-radius: 100%;
    background: #ffffff;
    font-size: 28px;
    position: absolute;
    top: -13px;
    left: 47px;
    @extend %transition;
  }
  .inner {
    h4 {
      &.title {
        font-size: 18px;
        font-weight: 700;
      }
    }
    p {
      font-size: 18px;
      margin-bottom: 0;
      color: rgba(29, 29, 36, 0.75);
      @media #{$lg-layout} {
        font-size: 16px;
      }
      @media #{$md-layout} {
        font-size: 16px;
      }
      @media #{$sm-layout} {
        font-size: 16px;
      }
      br {
        @media #{$lg-layout} {
          display: none;
        }

        @media #{$md-layout} {
          display: none;
        }

        @media #{$sm-layout} {
          display: none;
        }
      }
      a {
        color: rgba(29, 29, 36, 0.75);
        @extend %transition;
        &:hover {
          color: $theme-color;
        }
      }
    }
  }
  &:hover {
    .icon {
      background: $theme-color;
      border-color: $theme-color;
      color: #fff;
    }
  }
}

input,
textarea {
  @extend %transition;
}

.blog-comment-form .inner .rnform-group input:focus,
.blog-comment-form .inner .rnform-group textarea:focus {
  border-color: $theme-color;
}

.success-message {
  color: green;
  font-size: 14px;
  margin-top: 20px;
}
