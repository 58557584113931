.noctil-trustedpartner {
     position: relative;
     h2 {
          margin-bottom: 80px;
          @media #{$sm-layout} {
               margin-bottom: 40px;
          }
          @media #{$md-layout} {
               margin-bottom: 40px;
          }
     }
     .trusted-block{
          position: absolute;
          min-height: 224px;
          margin-top: 100px;
          margin-bottom: 40px;
          position:relative;
          @media #{$sm-layout} {
               min-height: auto;
               height: auto;
               height: auto;
               margin-bottom: 40px;
               margin-top: 0px;
          }
          @media #{$md-layout} {
               min-height: auto;
               height: auto;
               height: auto;
               margin-bottom: 40px;
               margin-top: 0px;
          }
          p {
               margin-top:20px;
               text-align: left;
               min-height: 120px;
               @media #{$sm-layout} {
                    min-height: auto;
               }
               @media #{$md-layout} {
                    min-height: auto;
               }
          }
          .trusted-content {
               position: absolute;
               bottom:5px;
               width:80%;
               margin-bottom: 30px;
               height:auto;
               @media #{$sm-layout} {
                    position: relative;
                    height: auto;
                    width:100%;
                    padding-left: 20px;
                    padding-right: 20px;
                    margin-bottom: 40px;
               }
               @media #{$md-layout} {
                    position: relative;
                    height: auto;
                    width:100%;
                    padding-left: 20px;
                    padding-right: 20px;
                    margin-bottom: 40px;
               }
               .imgouter {
                    height: 166px;
                    position:relative;
                    img {
                         position:absolute;
                         width:100%;
                         bottom:0;
                         left:0;
                    }
               }
          }
     }
}
.trusted-block-2 {
     min-height: 520px !important;
     @media #{$sm-layout} {
          min-height: auto !important;
     }
     @media #{$md-layout} {
          min-height: auto !important;
     }
     .title {
          font-style: normal;
          font-weight: 400;
          font-size: 22px;
          line-height: 28px;
          color: #1B1B1B;
     }
     p {
          font-style: normal !important;
          font-weight: 300 !important;
          font-size: 20px !important;
          line-height: 25px !important;
          color: #494949 !important;
          min-height: 360px !important;
          @media #{$sm-layout} {
               min-height: auto !important;
          }
          @media #{$md-layout} {
               min-height: auto !important;
          }
          strong {
               font-style: normal;
               font-weight: 400;
               font-size: 22px;
               line-height: 28px;
               color: #1B1B1B;
               display:block;
               margin-bottom:16px;
               margin-top:40px;
          }
     }
}