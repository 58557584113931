.core-feature-card-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 28px;
  border: 1px solid $border-color;
  border-radius: 8px;
  padding: 28px;
  padding-left: 16px;

  & h4 {
    height: 40px;
    font-size: 26px;
  }
  & img {
    width: 150px;
    height: 150px;
    object-fit: cover;
  }
}
.other-modules-wrapper {
  margin: auto;
  cursor: pointer;
  transition: all 0.2s;
  & span img {
    width: 250px;
    height: 250px;
    margin: auto;
  }

  & span a {
    font-size: 20px !important;
  }

  &:hover {
    transform: scale(1.03);
  }
}
