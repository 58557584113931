.mainmenunav {
  ul {
    &.mainmenu {
      display: flex;
      justify-content: flex-end;
      @extend %liststyle;
      flex-wrap: wrap;

      > li {
        margin: 0 18px;
        position: relative;

        > a,
        span {
          @extend %transition;
          color: #1b1b1b;
          font-size: 20px;
          font-weight: 500;
          padding: 20px 0;
          line-height: 25px;
          display: inline-block;
        }

        > ul {
          &.submenu {
            @extend %liststyle;
            min-width: 240px;
            height: auto;
            position: absolute;
            top: 100%;
            left: 0;
            z-index: 90;
            opacity: 0;
            visibility: hidden;
            background-color: #fff;
            box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
            text-align: left;
            @extend %transition;
            padding: 12px 0;
            border-radius: 4px;

            li {
              position: relative;

              a {
                font-size: 16px;
                font-weight: 600;
                padding: 5px 20px;
                font-size: 14px;
                display: block;
                color: #1b1b1b;
                margin: 0 10px;
                border-radius: 3px;
                @extend %transition;
              }

              a.active {
                color: #400d94;
              }

              &:hover {
                > a {
                  color: $theme-color;
                  background: rgba(249, 0, 77, 0.07);
                }
              }

              .submenu {
                @extend %liststyle;
                min-width: 240px;
                height: auto;
                position: absolute;
                top: 0;
                left: 100%;
                z-index: 90;
                opacity: 0;
                visibility: hidden;
                background-color: #fff;
                box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
                text-align: left;
                @extend %transition;
                padding: 12px 0;
                border-radius: 4px;
                display: none;
              }

              &:hover {
                .submenu {
                  opacity: 1;
                  visibility: visible;
                }
              }
            }
          }
        }

        &:hover {
          > ul {
            &.submenu {
              opacity: 1;
              visibility: visible;
            }
          }
        }

        &:hover {
          > a {
            color: $theme-color;
          }
        }

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

.humberger-menu {
  span {
    &.text-white {
      color: #c6c9d8 !important;
    }
  }
}

.color-black {
  .mainmenunav {
    ul {
      &.mainmenu {
        @extend %liststyle;

        > li {
          > a {
            color: rgba(27, 27, 27, 1);
          }

          &:hover {
            > a {
              color: $theme-color;
            }
          }

          &:active {
            > a {
              font-weight: 500;
            }
          }

          .link-active {
            color: red;
          }
        }
      }
    }
  }
}

@media #{$lg-layout} {
  .header-style-two .humberger-menu span.text-white {
    color: #1d1d24 !important;
  }
}

@media #{$md-layout} {
  .header-style-two .humberger-menu span.text-white {
    color: #1d1d24 !important;
  }
}

@media #{$sm-layout} {
  .header-style-two .humberger-menu span.text-white {
    color: #1d1d24 !important;
  }
}
