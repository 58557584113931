.error-page-inner {
     padding-top: 60px;
     padding-bottom: 30px;
     .title {
          font-size: 180px;
          line-height: 200px;
          font-weight: 300;
          color:#ffffff;
          font-style: normal;
          text-align: center;
     }
     .txt-gradient {
          background: -webkit-linear-gradient(98.1deg,#400d94 -1.58%, #76079d);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
     }
     p {
          font-style: normal;
          font-weight: 300;
          font-size: 20px;
          line-height: 25px;
          text-align: center;
          color:#1b1b1b;
          margin-bottom: 20px;
          strong {
               font-style: normal;
               font-weight: 400;
               font-size: 28px;
               line-height: 35px;
               color: #FFFFFF;
          }
     }
}
