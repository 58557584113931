.footer-wrapper {
  position: relative;
}

.footer-style-2 {
  padding-top: 80px;

  @media #{$md-layout} {
    padding-top: 54px;
  }

  @media #{$sm-layout} {
    padding-top: 44px;
  }

  p {
    color: #c6c9d8;
    font-size: 14px;
  }

  .logo {
    img {
      max-width: 170px;
    }

    p {
      font-weight: 300;
      font-size: 18px;
      line-height: 22.5px;
      color: #1b1b1b;
    }
  }

  .inner {
    .footer-social-icons {
      margin-top: 28px;

      span {
        display: inline-block;
        margin-right: 24px;
      }

      @media #{$sm-layout} {
        margin-top: 20px;
        margin-bottom: 28px;
      }

      @media #{$md-layout} {
        margin-top: 28px;
        margin-bottom: 28px;
      }
    }

    .contact {
      @media #{$sm-layout} {
        margin-top: 24px;
      }

      @media #{$md-layout} {
        margin-top: 28px;
      }

      .title {
        font-size: 22px;
        display: block;
        font-weight: 400;
        line-height: 32px;
        color: #1b1b1b;
        margin-bottom: 10px;
      }

      p {
        font-size: 20px;
        font-weight: 300;
        line-height: 32px;
        color: #1b1b1b;

        a {
          color: #1b1b1b;
          font-weight: 600;
        }

        a:hover {
          color: #4e12b1;
        }
      }
    }
  }

  .footerlinks {
    ul {
      &.footer-link {
        padding: 0;
        margin: 0;
        margin: -6px;

        li {
          margin: 6px;
          margin-bottom: 12px;

          a {
            width: auto;
            line-height: 28px;
            color: #1b1b1b;
            font-size: 22px;
            transition: 1s;
            font-weight: 400;

            @media #{$sm-layout} {
              font-size: 18px;
              line-height: 20px;
              font-weight: 400;
            }
          }

          &:hover {
            a {
              color: $theme-color;
            }
          }
        }
      }
    }
  }
}

.linkedin-icon {
  color: #1b1b1b;
  transition: 1s;
}

.linkedin-icon:hover {
  // color: #1166c2;
  color: #bd00ff;
}

.twitter-icon {
  color: #1b1b1b;
  transition: 1s;
}

.twitter-icon:hover {
  // color: #43A9E8;
  color: #bd00ff;
}

.youtube-icon {
  color: #1b1b1b;
  transition: 1s;
}

.youtube-icon:hover {
  // color: #E93F33;
  color: #bd00ff;
}

.copyright-text {
  p {
    color: #494949;
    font-size: 16px;
    font-weight: 300;
    text-align: center;
    margin-top: 80px;

    @media #{$md-layout} {
      margin-top: 30px;
    }

    @media #{$sm-layout} {
      margin-top: 30px;
    }
  }
}
