.homecarousel {
  padding-top: 60px;
  padding-bottom: 0px;
  .title {
    font-size: 44px;
    line-height: 55px;
    font-weight: 300;
    color: #ffffff;
    font-style: normal;
    text-align: center;
  }
  p {
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    color: #ffffff;
    margin-bottom: 20px;
    strong {
      font-style: normal;
      font-weight: 400;
      font-size: 28px;
      line-height: 35px;
      color: #ffffff;
    }
  }
  .image-wrapper {
    height: 100%;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
