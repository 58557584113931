.logos-marquee-wrapper {
  overflow-x: hidden;
  white-space: nowrap;
  position: relative;

  .logos-marquee-slide {
    display: inline-block;
    animation: slide 15s infinite linear;
    width: 100%; // Ensure both marquees have the same width

    img {
      height: 100px;
      object-fit: cover;
      object-position: center;
      margin: 20px;
      @media #{$sm-layout} {
        margin: 10px;
      }
    }
    @media #{$sm-layout} {
      display: flex;
      animation: slide 5s infinite linear;
      // justify-items: center;
      align-items: center;
    }
  }
  .repeated-logos-marquee-slide {
    @media #{$sm-layout} {
      display: none !important;
    }
  }

  &:hover .logos-marquee-slide {
    animation-play-state: paused;
  }

  &:before,
  &:after {
    position: absolute;
    top: 0;
    width: 80px;
    content: '';
    height: 100%;
    z-index: 2;
    @media #{$sm-layout} {
      width: 20px;
    }
  }

  &:before {
    left: 0;
    background: linear-gradient(to left, rgba(255, 255, 255, 0), #fff);
  }

  &:after {
    right: 0;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), #fff);
  }
}

@keyframes slide {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}
