.matchiq {
     p {
          font-style: normal;
          font-weight: 300;
          font-size: 20px;
          line-height: 25px;
          color: #494949;
     }
     p strong {
          font-weight: 400;
     }
     .bannerimg {
          img {
               width: 100%;
          }
     }
}