.homeBannerOuter {
  margin-top: 188px;
  padding-bottom: 60px;
  @media #{$sm-layout} {
    margin-top: 120px;
  }
  @media #{$md-layout} {
    margin-top: 120px;
  }
  .title {
    font-size: 44px;
    font-weight: 300;
    line-height: 55px;
    color: #1b1b1b;
    margin-bottom: 40px;
    @media #{$sm-layout} {
      font-size: 28px;
      line-height: 30px;
      font-weight: 300;
      margin-bottom: 20px;
    }
    @media #{$md-layout} {
      font-size: 32px;
      line-height: 40px;
      font-weight: 300;
      margin-bottom: 20px;
    }
  }

  .sub-title {
    font-size: 24px;
    font-weight: 400;
    line-height: 28px;
    color: #1b1b1b;
    margin-bottom: 25px;
    @media #{$sm-layout} {
      font-size: 18px;
      line-height: 20px;
      font-weight: 400;
      margin-bottom: 15px;
    }
    @media #{$md-layout} {
      font-size: 16px;
      line-height: 30px;
      font-weight: 400;
      margin-bottom: 10px;
    }
  }
  p {
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 25px;
    color: #1b1b1b;
    @media #{$sm-layout} {
      font-size: 16px;
      line-height: 20px;
      font-weight: 400;
      text-align: left;
    }
  }
  ul {
    margin-top: 16px;
    list-style: none;
    li::before {
      content: '•';
      color: red;
    }
    &.list-style {
      @extend %liststyle;
      margin-top: 16px;
      li {
        position: relative;
        padding-left: 16px;
        color: #1b1b1b;
        font-size: 20px;
        line-height: 25px;
        @media #{$sm-layout} {
          font-size: 16px;
          line-height: 22px;
        }
        &::before {
          position: absolute;
          width: 6px;
          height: 6px;
          background: rgba(189, 0, 255, 1);
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          content: '';
          border-radius: 100%;
        }
        & + li {
          margin-top: 10px;
        }
      }
    }
  }
  .bannerimg {
    img {
      width: 100%;
    }
    @media #{$sm-layout} {
      margin-top: 40px;
      width: 100%;
    }
    @media #{$md-layout} {
      margin-top: 40px;
      width: 100%;
    }
  }
}

.aboutBannerOuter {
  padding-bottom: 60px;
  @media #{$sm-layout} {
    margin-top: 50px;
  }
  @media #{$md-layout} {
    margin-top: 50px;
  }
  .title {
    font-size: 44px;
    font-weight: 300;
    line-height: 55px;
    color: #1b1b1b;
    margin-bottom: 40px;
    @media #{$sm-layout} {
      font-size: 28px;
      line-height: 30px;
      font-weight: 300;
      margin-bottom: 20px;
    }
    @media #{$md-layout} {
      font-size: 32px;
      line-height: 40px;
      font-weight: 300;
      margin-bottom: 20px;
    }
  }
  p {
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 25px;
    color: #494949;
  }
  .bannerimg {
    img {
      width: 100%;
    }
    @media #{$sm-layout} {
      margin-top: 40px;
      width: 100%;
    }
    @media #{$md-layout} {
      margin-top: 40px;
      width: 100%;
    }
  }
}

.custom-btn {
  display: inline-block;
  line-height: 42px;
  font-size: 20px;
  font-weight: 600;
  font-family: 'Lexend', sans-serif;
  position: relative;
  z-index: 2;
  border: 1px solid #1b1b1b;
  color: #1b1b1b;
  padding: 0px 30px;
  border-radius: 8px;
  box-shadow: 0px 5px 0px 0px #fdbf1a;
  transition: 0.5s;
  background-color: #ffffff;
  min-width: 150px;
  cursor: pointer;
  &:hover {
    border-color: #4e12b1;
    transform: none;
    transition: none;
    color: #4e12b1;
    border: 1px solid #4e12b1;
    background: #ffffff;
    border-radius: 8px;
    transition: 0.5s;
  }
  &:active {
    background-color: #ffffff;
    outline: none !important;
  }
  &:focus {
    background-color: #ffffff;
    color: #1b1b1b;
    outline: none !important;
    border: 1px solid #1b1b1b;
    box-shadow: 0px 5px 0px 0px #fdbf1a;
  }
  &:focus-visible {
    background-color: #ffffff;
    color: #1b1b1b;
    outline: none !important;
  }
  @media #{$sm-layout} {
    font-size: 16px;
  }
}

.effortlessly-maintain-metadata {
  h2 {
    line-break: loose;
    font-weight: 400;
  }
  h4 {
    font-weight: 600;
  }
}

.metadata-made-simple {
  background-color: #1b1b1b;
  color: white;
  h2 {
    line-break: loose;
    font-weight: 400;
    color: white !important;
    text-transform: capitalize;
  }
  p {
    width: 75%;
    font-size: 22px;
    line-height: 40px;
  }

  .card-wrapper {
    background: #263237;
    padding: 15px;
    border-radius: 4px;
    width: 100%;
    height: 300px;
    position: relative;
    box-shadow: 1px 1px 7px 2px rgba(0, 0, 0, 0.103);
    -webkit-box-shadow: 1px 1px 7px 2px rgba(0, 0, 0, 0.192);
    -moz-box-shadow: 1px 1px 7px 2px rgba(0, 0, 0, 0.155);
    color: white;

    .inner-wrap {
      display: flex;
      flex-direction: column;
      gap: 35px;
      h4 {
        color: white;
        width: 85%;
      }
    }
  }
}

.discover-partnership {
  .card-wrapper {
    min-height: 380px;
    position: relative;
    img {
      border-radius: 28px;
      width: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  h2 {
    color: #1b1b1b !important;
  }
  h4 {
    color: #1b1b1b !important;
    border-left: 4px solid #1b1b1b;
    padding-left: 10px;
    margin-top: 16px;
    font-weight: 500;
    @media #{$sm-layout} {
      margin-bottom: 25px;
    }
  }
  background-color: #fbfbfb;
}

.resources-for-you {
  background-color: #fbfbfb;
  .card-wrapper {
    min-height: 440px;
    padding: 10px;
    max-width: 320px;
    background-color: #ffffff;
    box-shadow: 2px 7px 21px -3px rgba(0, 0, 0, 0.32);
    -webkit-box-shadow: 2px 7px 21px -3px rgba(0, 0, 0, 0.32);
    -moz-box-shadow: 2px 7px 21px -3px rgba(0, 0, 0, 0.32);
    position: relative;
    transition: transform 0.1s;
    cursor: pointer;
    img {
      width: 100%;
      object-fit: cover;
      object-position: center;
    }
    &:hover {
      transform: scale(1.05);
    }

    h6 {
      color: #494949e2;
      font-size: 14px;
    }

    button {
      all: unset;
      width: 90%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      position: absolute;
      bottom: 10px;
      // left: 10px;font-size: 16px;
      color: #124ab1;
      font-size: 16px;
      &:hover {
        color: #063386;
      }
    }
  }
}

.noctil-for-record-labels {
  h3 {
    font-size: 20px;
  }

  h2 {
    font-size: 50px !important;
    line-height: 60px !important;
  }

  h5 {
    color: #fff;
  }
}

.bg-lightgray {
  background-color: #fbfbfb;
}

.get-started {
  position: relative;
  min-height: 60vh;
  margin-bottom: 120px;
  .form {
    position: absolute;
    top: 25px;
    right: 50px;
    .custom-btn {
      margin: auto;
    }
    padding: 20px;
    box-shadow: 1px 1px 7px 2px rgba(0, 0, 0, 0.103);
    -webkit-box-shadow: 1px 1px 7px 2px rgba(0, 0, 0, 0.192);
    -moz-box-shadow: 1px 1px 7px 2px rgba(0, 0, 0, 0.155);
    @media #{$sm-layout} {
      position: relative;
      left: 0px;
    }
  }
  @media #{$sm-layout} {
    margin-bottom: 20px;
  }
}
