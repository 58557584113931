.our-journey {
     padding-top: 60px;
     padding-bottom: 60px;
     h2 {
          color: #ffffff;
     }
     .content {
          padding-top: 40px;
          max-width: 70%;
          .year {
               font-style: normal;
               font-weight: 400;
               font-size: 22px;
               line-height: 28px;
               color: #FDBF1A;
               margin-bottom: 10px;
          }
          p {
               font-style: normal;
               font-weight: 300;
               font-size: 18px;
               line-height: 22px;
               color: #FFFFFF;
          }
     }
     .mobile-show {
          .content {
               padding-left: 16px;
               padding-top: 0px;
               max-width: 70%;
          }
     }
     p {
          font-style: normal;
          font-weight: 300;
          font-size: 20px;
          line-height: 25px;
          color: #FFFFFF;
     }
     img {
          @media #{$sm-layout} {
               margin-top: 0px;
          }
          @media #{$md-layout} {
               margin-top: 0px;
          }
     }
}