@media (min-width: 1200px) {
  .container {
    max-width: 1260px;
  }
}
.breadcrumb-area {
  padding-top: 80px;
  padding-bottom: 80px;
  @media #{$md-layout} {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  @media #{$sm-layout} {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.breadcrumb-inner {
  text-align: center;

  h2 {
    &.title {
      // background: #f81f01;
      // background: linear-gradient(145deg, #f81f01 0%, #ee076e 100%);
      // -webkit-background-clip: text;
      // -webkit-text-fill-color: transparent;
      color: #1b1b1b;
    }
  }

  ul {
    &.page-list {
      @extend %liststyle;

      li {
        display: inline-block;
        color: #1b1b1b;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 25px;
        color: #1b1b1b;
        opacity: 0.5;
        text-transform: lowercase;
        a {
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 25px;
          color: #1b1b1b;
          text-transform: lowercase;
        }

        &.breadcrumb-item {
          &.active {
            color: #1b1b1b;
            opacity: 0.5;
          }
        }
      }
    }
  }
}

.breadcrupm-style--2 {
  h2 {
    &.title {
      text-transform: uppercase;
      font-size: 72px;
      font-style: normal;
      font-weight: 300;
      font-size: 44px;
      line-height: 55px;
      text-align: center;
      color: #1b1b1b;

      @media #{$sm-layout} {
        font-size: 24px;
        line-height: 59px;
      }
    }
  }

  span {
    font-size: 22px;
    color: #c6c9d8;
  }
}

.rn-page-title {
  h2 {
    &.title {
      font-size: 44px;

      @media #{$lg-layout} {
        font-size: 44px;
      }

      @media #{$md-layout} {
        font-size: 44px;
      }

      @media #{$sm-layout} {
        font-size: 24px;
        line-height: 30px;
      }
    }
  }

  p {
    font-size: 22px;
    line-height: 27px;
    color: #c6c9d8;
  }
}

.backto-top {
  > div {
    z-index: 999;
    width: 46px;
    height: 46px;
    line-height: 42px;
    border-radius: 8px;
    background-color: #ffffff;
    text-align: center;
    overflow: hidden;
    z-index: 999 !important;
    border: 1px solid #1b1b1b;
    bottom: 80px !important;
    // box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
    &:hover {
      height: 42px;
      box-shadow: 0px 4px 0px 0px #fdbf1a;
      bottom: 84px !important;
      transition: 0.5s;
    }
  }

  svg {
    font-size: 12px !important;
    color: $theme-color;
    width: 30px;
    height: 30px;
  }
}
