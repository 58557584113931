.believe-in {
     padding-top: 60px;
     padding-bottom: 30px;
     .title {
          font-size: 44px;
          line-height: 55px;
          font-weight: 300;
          color:#ffffff;
          font-style: normal;
          text-align: center;
     }
     p {
          font-style: normal;
          font-weight: 300;
          font-size: 20px;
          line-height: 25px;
          text-align: center;
          color:#ffffff;
          margin-bottom: 20px;
          strong {
               font-style: normal;
               font-weight: 400;
               font-size: 28px;
               line-height: 35px;
               color: #FFFFFF;
          }
     }
     .believe-block{
          min-height: 180px;
          position:relative;
          @media #{$sm-layout} {
               min-height: auto;
               height: auto;
               height: auto;
          }
          @media #{$md-layout} {
               min-height: auto;
               height: auto;
               height: auto;
          }
          p {
               margin-top:20px;
          }
          .believe-content {
               position: absolute;
               bottom:5px;
               width:80%;
               margin-bottom: 30px;
               height:auto;
               text-align: center;
               @media #{$sm-layout} {
                    position: relative;
                    height: auto;
                    width:100%;
                    padding-left: 20px;
                    padding-right: 20px;
                    margin-bottom: 40px;
               }
               @media #{$md-layout} {
                    position: relative;
                    height: auto;
                    width:100%;
                    padding-left: 20px;
                    padding-right: 20px;
                    margin-bottom: 40px;
               }
          }
     }

}