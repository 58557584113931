.solutionListOuter {
  .solutionItem {
    margin-bottom: 120px;
    @media #{$sm-layout} {
      margin-bottom: 60px;
    }
    @media #{$md-layout} {
      margin-bottom: 60px;
    }
    .title {
      font-style: normal;
      font-weight: 300;
      font-size: 40px;
      line-height: 50px;
      color: #1b1b1b;
      margin-bottom: 28px;
      &:hover {
        color: #4e12b1;
        cursor: pointer;
      }
    }
    p {
      font-style: normal;
      font-weight: 300;
      font-size: 20px;
      line-height: 26px;
      color: #494949;
    }
    .image-block {
      text-align: left;
      @media #{$sm-layout} {
        text-align: center;
        margin-bottom: 0px;
        margin-top: 20px;
      }
      img {
        width: 90%;
      }
    }
  }
}
input[type='file'] {
  width: 350px;
  max-width: 100%;
  color: #444;
  padding: 0px;
  background: #fff;
  border-radius: 10px;
  border: none;
  height: auto !important;
}
input[type='file']::file-selector-button {
  margin-right: 20px;
  border: 1.5px solid #d9d9d9;
  border-radius: 8px;
  background: #ffffff;
  padding: 5px 10px;
  color: #494949;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
}
input[type='file']::file-selector-button:hover {
  background: #4e12b1;
  color: #ffffff;
}
.accordion .card {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column-reverse;
  cursor: pointer;
}
.accordion .card .card-header {
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 50px;
  color: #1b1b1b;
  cursor: pointer;
}
.card-header {
  background-color: #ffffff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  transition: background 0.2s ease-in-out;
}
.accordion .card .card-header:hover {
  background-color: #ffffff;
  transition: background 0.2s ease-in-out;
  color: #4e12b1;
}
.accordion .card:not(:last-of-type) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-body {
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 25px;
  color: #494949;
}
.card-header .accordion-arrow {
  transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
}
.show + .card-header .accordion-arrow {
  transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
}
.show + .card-header {
  color: #4e12b1 !important;
}
.content {
  h2 {
    font-style: normal;
    font-weight: 300;
    font-size: 40px;
    line-height: 50px;
    color: #4e12b1;
    margin-bottom: 40px;
  }
  h5 {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
    color: #494949;
    margin-bottom: 0px;
  }
  p {
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 25px;
    color: #494949;
    margin-bottom: 20px;
    strong {
      font-weight: 400;
      color: #494949;
    }
  }
  a {
    color: #494949;
  }
  a:hover {
    color: #4e12b1;
  }
  ul {
    margin-bottom: 20px !important;
    li {
      padding-left: 20px !important;
    }
  }
  ol {
    margin-bottom: 20px !important;
    li {
      font-style: normal;
      font-weight: 300;
      font-size: 20px;
      line-height: 28px;
      text-transform: capitalize;
      color: #494949;
    }
  }
  table {
    border: 1px solid #d9d9d9;
    tr {
      th {
        font-style: normal;
        font-weight: 300;
        font-size: 20px;
        line-height: 25px;
        color: #1b1b1b;
        text-transform: none;
        border: 1px solid #d9d9d9;
      }
      td {
        font-style: normal;
        font-weight: 300;
        font-size: 20px;
        line-height: 25px;
        color: #494949;
        border: 1px solid #d9d9d9;
      }
    }
  }
}
.color-primary {
  color: #4e12b1 !important;
}
.txt-underline {
  text-decoration: underline;
}
.blogList {
  .blogItem {
    margin-bottom: 40px;
    @media #{$sm-layout} {
      margin-bottom: 16px;
    }
    @media #{$md-layout} {
      margin-bottom: 40px;
    }
    img {
      border-radius: 8px 8px 0px 0px;
      width: 100%;
    }
    .content-outer {
      display: flex;
      flex-direction: column;
      min-height: 160px;
      padding: 14px 14px;
      background: #ffffff;
      border-width: 0px 1px 1px 1px;
      border-style: solid;
      border-color: #fdbf1a;
      border-radius: 0px 0px 8px 8px;
      @media #{$sm-layout} {
        display: block;
        min-height: auto;
      }
      @media #{$md-layout} {
        display: block;
        min-height: auto;
      }
      .title {
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 28px;
        color: #1b1b1b;
        @media #{$sm-layout} {
          margin-bottom: 12px;
        }
        @media #{$md-layout} {
          margin-bottom: 12px;
        }
      }
      .date {
        margin-top: auto;
      }
      a {
        color: #1b1b1b;
      }
      a:hover {
        color: #4e12b1;
      }
    }
  }
}

.blogDetails {
  h4 {
    font-style: normal;
    font-weight: 300;
    font-size: 28px;
    line-height: 50px;
    color: #494949;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  h5 {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
    color: #494949;
    margin-bottom: 0px;
  }
  .imgfull {
    width: 100%;
    max-width: 100%;
  }
  .author-details {
    margin-top: 40px;
    margin-bottom: 24px;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 25px;
    color: #494949;
    .blog-details-separator {
      display: inline-block;
      margin-left: 8px;
      margin-right: 8px;
    }
  }
  .title {
    font-family: 'Lexend';
    font-style: normal;
    font-weight: 300;
    font-size: 40px;
    line-height: 50px;
    color: #1b1b1b;
    margin-bottom: 40px;
  }
  p {
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 25px;
    color: #494949;
    strong {
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 25px;
      color: #1b1b1b;
    }
  }
}
.color-light {
  color: #494949 !important;
}
.CookieConsent {
  font-family: 'Lexend', sans-serif;
  font-size: 14px;
  background: #4e12b1 !important;
}
.CookieConsent a {
  text-decoration: underline;
  cursor: pointer;
}
.blogCarousel {
  img {
    width: 100%;
    border-radius: 8px 8px 0px 0px;
  }
  .content {
    background: #ffffff;
    border-width: 0px 1px 1px 1px;
    border-style: solid;
    border-color: #fdbf1a;
    border-radius: 0px 0px 8px 8px;
    padding: 16px 12px;
    min-height: 140px;
    position: relative;
    .title {
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 28px;
      color: #1b1b1b;
      position: absolute;
      top: 12px;
    }
    .publisedDate {
      font-style: normal;
      font-weight: 300;
      font-size: 18px;
      line-height: 25px;
      color: #494949;
      margin-bottom: 0px;
      position: absolute;
      bottom: 12px;
    }
  }
}
.blogCarousel .slick-prev:before,
.blogCarousel .slick-next:before {
  font-size: 40px;
  line-height: 1;
  color: #1b1b1b;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.blogCarousel .slick-prev:before {
  content: '‹';
}
[dir='rtl'] .blogCarousel .slick-prev:before {
  content: '›';
}

[dir='rtl'] .blogCarousel .slick-next {
  left: -10px;
  top: 70px;
  right: auto;
}
.blogCarousel .slick-next:before {
  content: '›';
}
[dir='rtl'] .blogCarousel .slick-next:before {
  content: '‹';
}

@for $i from 1 through 4 {
  .grid-center-cols--#{$i} {
    display: grid;
    grid-template-columns: repeat(#{$i}, 1fr);
    grid-gap: 25px;
    justify-content: center;

    @media #{$sm-layout} {
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 10px;
    }
    @media #{$md-layout} {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 10px;
    }
  }
}

@for $i from 1 through 4 {
  @for $j from 1 through 10 {
    $gap: $j * 10;

    .grid-center-cols--#{$i}--gap--#{$gap} {
      display: grid;
      grid-template-columns: repeat(#{$i}, 1fr);
      grid-gap: #{$gap}px;
      justify-content: center;

      @media #{$sm-layout} {
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 10px;
      }
      @media #{$md-layout} {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 10px;
      }
    }
  }
}

@for $i from 1 through 40 {
  .flex-col-start-gap--#{$i} {
    display: flex;
    flex-direction: column;
    gap: #{$i}px;
    justify-content: flex-start;
  }
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-col-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.text-start {
  text-align: start;
}

.learn-more-button {
  all: unset;
  position: absolute;
  bottom: 12px;
  left: 16px;
  cursor: pointer;
  border: 1px solid white;
  border-radius: 4px;
  padding: 6px 14px;
  transition: transform 0.1s;
  span {
    background-color: white;
    padding: 4px 6px;
    border-radius: 50%;
    margin-left: 10px;
    color: #1b1b1b;
  }
  &:hover {
    transform: scale(1.05);
  }
  @media #{$sm-layout} {
    position: relative;
    width: 40%;
  }
}

.mx-auto {
  margin: auto;
}
