.noctil-videoframe {
     h2 {
          margin-bottom: 40px;
     }
     .solution-block{
          min-height: 224px;
          margin-bottom: 40px;
          position:relative;
          @media #{$sm-layout} {
               min-height: auto;
               height: auto;
               height: auto;
               margin-bottom: 40px;
          }
          @media #{$md-layout} {
               min-height: auto;
               height: auto;
               height: auto;
               margin-bottom: 40px;
          }
          p {
               margin-top:20px;
          }
          .solution-content {
               position: absolute;
               bottom:5px;
               width:80%;
               margin-bottom: 30px;
               height:auto;
               @media #{$sm-layout} {
                    position: relative;
                    height: auto;
                    width:100%;
                    padding-left: 20px;
                    padding-right: 20px;
                    margin-bottom: 40px;
               }
               @media #{$md-layout} {
                    position: relative;
                    height: auto;
                    width:100%;
                    padding-left: 20px;
                    padding-right: 20px;
                    margin-bottom: 40px;
               }
          }
     }
}