.noctil-features {
  @media #{$sm-layout} {
    margin-bottom: 0px;
  }
  @media #{$md-layout} {
    margin-bottom: 0px;
  }
  .feature-block {
    margin-top: 80px;
    p {
      font-style: normal;
      font-weight: 300;
      font-size: 20px;
      line-height: 25px;
      color: #494949;
    }
    img {
      width: 250px;
      @media #{$sm-layout} {
        margin-bottom: 40px;
      }
      @media #{$md-layout} {
        margin-bottom: 40px;
      }
    }
  }
}
